@import "../../../../Constant.scss";

.carousel-root{
    width: 80vw;
    max-width: 550px;
    max-height: 80vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carousel .slider-wrapper.axis-horizontal .slider {
    align-items: center !important;
}

.carousel .slide img {
    border-radius: 30px !important;
    max-height: 80vh;
    max-width: 550px;
    width: auto !important;
    object-fit: contain !important;
}

.carousel.carousel-slider .control-arrow {
    border-radius: 30px !important;
}

.carousel .carousel-status {
    display: none !important;
}

.list-template{
    padding: 2vh 2vh 0% 2vh;
    display: flex;
    flex-direction: column;
    grid-gap: 2vh;
    height: 80vh;
    .list-template-element{
        display: grid;
        align-content: space-between;
        height: 100%;
        .list-template-element-contect{
            display: grid;
            grid-gap: 1vh;
            grid-template-rows: 5vh 5vh 65vh 5vh;
            .element-contect-header{
                display: flex;
                align-content: flex-start;
                align-items: flex-start;
                justify-content: space-between;
                .element-contect-input-search{
                    font-size: 14px;
                    color: $color6;
                    padding:12px 15px;
                    border-radius: 15px;
                    border: none;
                    @extend %glass;
                    font-family: $font;
                    &:focus, &:active {
                        outline: none;
                    }
                }
            }
            .element-contect-numb{
                display: grid;
                justify-items: center;
                background: white;
            }
            .element-contect-list{
                display: flex;
                flex-direction: column;
                height: 60vh;
                grid-gap: 10px;
                padding: 20px;
                overflow-y: auto; 
                overflow-x: hidden;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.562);
                    border-radius: 10px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 10px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 8px;
                }
            }
            .element-title-contect-list{
                text-decoration: none;
                grid-gap: 20px;
                color: $color6 ;
                align-items: center;
                align-content: center;
                padding: 10px 30px;
                font-size: 12px;
                text-align: center;
                color: $color7;
                justify-items: center;
                background: none;
                @extend %trans;
            }
        }
    }
}

.open-create{
    position: fixed;
    display: grid;
    z-index: 9999;
    height: 100vh;
    width: 100%;
    @extend %glass1;
    justify-items: center;
    align-items: center;
    .space-top{
        margin-top: 20px;
    }
    .crop-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 500px;
        max-height: 500px;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 40px;
        padding: 2vw;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    .crop-area {
        position: relative;
        width: 100%;
        height: 400px; /* Ajusta el tamaño del área de recorte */
        max-height: 400px;
        background: #bebebe;
        border-radius: 30px;
        overflow: hidden;
        img {
            width: 100%;
            height: auto; /* Mantiene la proporción original */
            max-height: 100%;
            object-fit: contain; /* Asegura que la imagen se ajuste sin distorsión */
        }
    }
    .crop-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        width: 100%;
    }
    .section-open-create{
        display: grid;
        padding: 5%;
        color:$color6;
        text-align: center;
        background: $color8;
        border-radius: 50px;
        max-width: 800px;
        width: 80vw;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.349);
        .section-open-create-form{
            display: grid;
            grid-template-columns: 33% auto;
            grid-gap: 5%;
            align-items: start;
            .open-create-form-img{
                display: grid;
                position: relative;
                .custom-file-upload{
                    position: absolute;
                    margin-top: -70px;
                    color: $color11;
                    background: #1E67E2;
                    border: #1E67E2 solid 2px;
                    padding: 10px 15px;
                    text-decoration: none;
                    border-radius: 16px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    grid-gap: 10px;
                    font-size: 14px;
                    @extend %trans;
                    justify-content: center;
                    font-family: $font;
                    cursor: pointer;
                    svg{
                        font-size: 22px;
                    }
                    &:hover{
                        color: #1E67E2;
                        background: none;
                        @extend %trans;
                        svg{
                            font-size: 24px;
                            color: #1E67E2;
                        }
                    }
                }
                .input-img{
                    border-radius: 30px; /* Esto crea un círculo */
                    overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                    position: relative; /* Permite centrar vertical y horizontalmente */
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                        object-position: center center; /* Centra la imagen */
                    }
                    .input-upload{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
            .open-create-form-dates{
                display: flex;
                flex-direction: column;
                max-height: 65vh;
                padding: 0 10px 10px 10px;
                overflow-y: auto; 
                overflow-x: hidden;
                /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(169, 169, 169, 0.349);
                    border-radius: 5px;
                }
                /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                &::-webkit-scrollbar-track {
                    @extend %glass;
                    border-radius: 5px;
                }
                /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                &::-webkit-scrollbar {
                    width: 5px;
                }
                .section-form-create{
                    display: grid;
                    label{
                        font-size: 10px;
                        padding: 10px;
                        text-align: start;
                    }
                    .button-group-create{
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                        grid-gap: 10px;
                    }
                }
                .custom-file-pdf{
                    color: $color11;
                    background: $color2;
                    border:  $color2 solid 2px;
                    padding: 10px;
                    text-decoration: none;
                    border-radius: 16px;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    grid-gap: 10px;
                    font-size: 10px;
                    @extend %trans;
                    justify-content: center;
                    font-family: $font;
                    cursor: pointer;
                    .custom-file-upload{
                        display: flex;
                    }
                    svg{
                        font-size: 16px;
                        margin-right: 5px;
                    }
                    &:hover{
                        color:  $color2;
                        background: none;
                        @extend %trans;
                        svg{
                            color:  $color2;
                        }
                    }
                }
                .input-form-create{
                    display: grid;
                    width: 100%;
                    grid-gap: 5px;
                    label{
                        padding: 0 15px;
                        font-size: 10px;
                        text-align: left;
                    }
                    input{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 2%;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    .input-error{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 2%;
                        border-radius: 16px;
                        color:$color10;
                        @extend %glass1;
                        background: #ffb8b85b;
                        border: solid 1px #ffb8b8a9; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    select{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 2%;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    textarea{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 2%;
                        border-radius: 16px;
                        @extend %glass1;
                        resize: vertical; 
                        max-height: 100px;
                        min-height: 50px;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                }
                .form-create-botton {
                    display: grid;
                    grid-gap: 5px;
                    grid-template-columns: repeat(3, 1fr);
                }
            }
        }
        .section-open-create-form-one{
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 15px;
            align-items: start;
            padding: 30px 10px;
            .section-open-one-dates{
                display: grid;
                grid-template-columns: 65% auto;
                grid-gap: 2%;
                .open-one-dates-left{
                    text-align: start;
                    display: grid;
                    grid-gap: 5px;
                    .info-date-warehouse{
                        padding: 10px 20px;
                        border-radius: 20px;
                        @extend %glass;
                        p{
                            font-size: 11px;
                        }
                    }
                }
                .open-one-dates-right{
                    text-align: start;
                    display: grid;
                    grid-gap: 5px;
                }
                .input-form-create-order{
                    display: grid;
                    width: 100%;
                    grid-gap: 5px;
                    label{
                        padding: 0 15px;
                        font-size: 10px;
                        text-align: left;
                    }
                    input{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 5%;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    .input-error{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 5%;
                        border-radius: 16px;
                        color:$color10;
                        @extend %glass1;
                        background: #ffb8b85b;
                        border: solid 1px #ffb8b8a9; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    select{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 2%;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    textarea{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 5%;
                        border-radius: 16px;
                        @extend %glass1;
                        resize: vertical; 
                        max-height: 100px;
                        min-height: 50px;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                }
            }
            .section-open-one-product{
                display: grid;
                grid-template-rows: 20px auto;
                .section-open-one-product-title{
                    display: grid;
                    grid-template-columns: 30px auto 100px 110px 100px;
                }
                .section-open-one-product-scroll{
                    display: flex;
                    flex-direction: column;
                    grid-gap: 10px;
                    position: relative;
                    .one-product-scroll-productselect{
                        position: relative;
                        display: grid;
                        grid-gap: 10px;
                        max-height: 20vh;
                        padding: 10px 10px 10px 10px;
                        overflow-y: auto; 
                        overflow-x: hidden;
                        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                        &::-webkit-scrollbar-thumb {
                            background-color: rgba(169, 169, 169, 0.562);
                            border-radius: 10px;
                        }
                        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                        &::-webkit-scrollbar-track {
                            @extend %glass;
                            border-radius: 10px;
                        }
                        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                        &::-webkit-scrollbar {
                            width: 4px;
                        }
                    }
                    .selected-products {
                        margin-top: 20px;
                    }
                    .container-form-one-search{
                        display: grid;
                        height: 70px;
                        position: relative;
                    }
                    .input-form-one-search{
                        border-radius: 16px;
                        @extend %glass1;
                        padding: 10px 5px;
                        display: grid;
                        position: absolute;
                        width: 100%;
                        z-index: 99;
                        .one-product-scroll-product{
                            display: grid;
                            grid-gap: 10px;
                            max-height: 15vh;
                            padding: 10px 10px 10px 10px;
                            overflow-y: auto; 
                            overflow-x: hidden;
                            /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                            &::-webkit-scrollbar-thumb {
                                background-color: rgba(169, 169, 169, 0.562);
                                border-radius: 10px;
                            }
                            /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                            &::-webkit-scrollbar-track {
                                @extend %glass;
                                border-radius: 10px;
                            }
                            /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                            &::-webkit-scrollbar {
                                width: 4px;
                            }
                        }
                        input{
                            font-family: Poppins;
                            font-size: 12px;
                            padding: 5px 10px;
                            border-radius: 5px;
                            border: none;
                            background: none;
                            &:focus,
                            &:active {
                                outline: none; 
                            }
                        }
                    }                    
                    .item-one-product{
                        display: grid;
                        grid-template-columns: 30px auto 90px 150px 60px;
                        justify-items: center;
                        align-items: center;
                        padding: 5px;
                        border-radius: 10px;
                        @extend %glass1;
                        img{
                            border-radius: 10px;
                        }
                        p{
                            font-size: 11px;
                            columns: $color7;
                        }
                        input{
                            font-family: Poppins;
                            font-size: 12px;
                            padding: 5px 10px;
                            width: 70px;
                            border-radius: 5px;
                            text-align: center;
                            border: none;
                            @extend %glass1;
                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                                height: 20px;
                                width: 20px;
                                background: none;
                                color: gray; // Color de las flechas
                                opacity: 1; // Mostrar las flechas en gris
                            }
                            &:focus,
                            &:active {
                                outline: none; // Elimina el borde al enfocarse
                            }
                        }
                        .icon-edit{
                            display: grid;
                            justify-items: center;
                            align-items: center;
                            svg{
                                border-radius: 10px;
                                background: $color10;
                                color: $color8;
                                padding: 5px;
                                font-size: 16px;
                                @extend %trans;
                                &:hover{
                                    font-size: 20px;
                                    padding: 5px;
                                    @extend %trans;
                                }
                            }
                        }
                        .boton-item-one-product{
                            font-size: 10px;
                            background: $color13;
                            color: $color8;
                            border-radius: 10px;
                            padding: 5px;
                            border: 1px solid $color13;
                            @extend %trans;
                            &:hover{
                                border: 1px solid $color13;
                                color: $color13;
                                background: none;
                                @extend %trans;
                            }
                        }
                    }
                }
            }
            .input-form-one-create{
                display: grid;
                width: 100%;
                grid-gap: 5px;
                label{
                    padding: 0 15px;
                    font-size: 10px;
                    text-align: left;
                }
                input{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 2%;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                .input-error{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 5%;
                    border-radius: 16px;
                    color:$color10;
                    @extend %glass1;
                    background: #ffb8b85b;
                    border: solid 1px #ffb8b8a9; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 5%;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                textarea{
                    font-family: Poppins;
                    font-size: 12px;
                    padding: 10px 2%;
                    border-radius: 16px;
                    @extend %glass1;
                    resize: vertical; 
                    max-height: 100px;
                    min-height: 50px;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .form-one-create-botoners{
                display: grid;
                margin-top: 10px;
                grid-template-columns: repeat(3, 1fr);
                grid-gap: 10px;
            }
            .form-one-create-botoners1{
                display: grid;
                margin-top: 10px;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
                .input-form-messenger{
                    display: grid;
                    width: 100%;
                    grid-gap: 5px;
                    label{
                        padding: 0 15px;
                        font-size: 10px;
                        text-align: left;
                    }
                    input{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 5%;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    .input-error{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 5%;
                        border-radius: 16px;
                        color:$color10;
                        @extend %glass1;
                        background: #ffb8b85b;
                        border: solid 1px #ffb8b8a9; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    select{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 2%;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    textarea{
                        font-family: Poppins;
                        font-size: 12px;
                        padding: 10px 5%;
                        border-radius: 16px;
                        @extend %glass1;
                        resize: vertical; 
                        max-height: 100px;
                        min-height: 50px;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                }
            }
        }
    }
}

