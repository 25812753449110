@import "../../../../Constant.scss";

.container-register{
    display: grid;
    width: 90%;
    padding: 20px;
    color:$color6;
    text-align: center;
    grid-gap: 10px;
    max-width: 600px;
    background: $color8;
    border-radius: 30px;
    margin: 20px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.349);
    .brand{
        display: grid;
        justify-items: center;
        margin: 30px 0;
        img{
            width: 50%;
            max-width: 120px;
            height: auto;
        }
    }
    span{
        color: $color2;
    }
    .form-contact{
        text-align: left;
        display: grid;
        width: 90%;
        grid-gap: 5px;
        padding: 0 5% 10% 5%;
        .input-date-form{
            display: grid;
            label{
                padding: 0 15px;
                font-size: 11px;
                color:$color6;
            }
            input{
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                width: 97%;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            select{
                font-family: Poppins;
                font-size: 14px;
                padding: 8px 10px;
                border-radius: 16px;
                @extend %glass1;
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-message{
            display: grid;
            label{
                padding: 0 15px;
                font-size: 12px;
                color: $color8;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                @extend %glass1;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .button-form{
            font-family: Poppins;
            font-size: 16px;
            padding: 8px;
            font-weight: 700;
            color: $color8;
            border: 2px solid $color2;
            border-radius: 16px;
            background: $color2;
            @extend %trans;
            cursor: pointer;
            margin-top: 5px;
            &:hover{
                font-weight: 700;
                color: $color2;
                border: 2px solid $color2;
                border-radius: 16px;
                background: none;
                @extend %trans;
            }
        }
        .button-form2{
            font-family: Poppins;
            font-size: 16px;
            padding: 8px;
            font-weight: 700;
            color: $color1;
            border: 2px solid $color8;
            border-radius: 16px;
            background: $color8;
            @extend %trans;
            cursor: pointer;
            margin-top: 5px;
            &:hover{
                font-weight: 700;
                color: $color8;
                border: 2px solid $color8;
                border-radius: 16px;
                background: none;
                @extend %trans;
            }
        }
        .recaptcha{
            display: grid;
            justify-content: center;
            margin: 10px 0;
        }
    }
}


.exict-form{
    margin-top: 20px;
    background: none;
    padding: 20px 40px;
    border-radius: 20px;
    color: $color2;
    svg{
        font-size: 6vw;
    }
    .succeso{
        text-align: center;
    }
}



@media (max-width: 750px) {
    .g-recaptcha {
        transform:scale(0.8);
        transform-origin:0 0;
    }
    .container-register{
        display: grid;
        width: 80%;
        padding: 10px;
        .form-contact{
            text-align: left;
            display: grid;
            width: 90%;
            grid-gap: 5px;
            padding: 0 5% 10% 5%;
        }
    }
}

