@import "../../../../Constant.scss";

.item-list-template{
    display: grid;
    text-decoration: none;
    grid-gap: 50px 20px;
    color: $color6 ;
    align-items: center;
    align-content: center;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    color: $color7;
    justify-items: center;
    background: none;
    @extend %trans;
    @extend %glass;
    border-radius: 20px;
    cursor: pointer;
    .avatar {
        border: none;
        background: none;
        font-family: $font;
        text-align: start;
        .avatar-letter{
            font-size: 15px;
            font-weight: 300;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            line-height: 0px;
            background: $color1;
            color: $color8;
            padding: 5px;
            height: 18px;
            width: 18px;
            border-radius: 50%;
            display: grid;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
        }
        .avatar-img {
            width: 30px;
            height: 30px;
            border-radius: 50%; /* Esto crea un círculo */
            overflow: hidden; /* Corta cualquier contenido fuera del círculo */
            position: relative; /* Permite centrar vertical y horizontalmente */
            .avatar-img img {
                width: 100%;
                height: 100%;
                object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                object-position: center center; /* Centra la imagen */
            }
        }
    };
    p{
        font-size: 12px;
    }
    @extend %trans;
    &:hover{
        opacity: 0.8;
        p{
            font-size: 13px;
            @extend %trans;
        }
    }
}