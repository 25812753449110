@import "../../../../Constant.scss";
.espace{
    margin:10px;
}

.terminos-condiciones-siigo{
    color: $color1;
    font-size: 12px;
    &:hover{
        color: $color2;
    }
}
.section-contect-siigo{
    display: grid;
    width: 100%;
    justify-items: center;
    .section-siigo-contect-container{
        z-index: 1;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        min-height: 80vh;
        position: relative;
        grid-template-areas: "section-siigo-title  section-siigo-img";
        margin-top: 130px;
        max-width: 1400px;
        grid-gap: 5%;
        width: 85%;
    }
    .section-siigo-title{
        grid-area: section-siigo-title;
        display: grid;
        align-items: center;
        align-content: center;
        grid-gap: 20px;
        justify-items:end;
        text-align: end;
        z-index: 99;
        .register-sigo-title{
            display: grid;
            text-align: center;
            grid-gap: 20px;
            width: 100%;
            justify-items: center;
            justify-content: center;
            .register-sigo-title-img{
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 120px;
                }
            }
        }
        .container-form-siigo{
            display: grid;
            width: 100%;
            .form-siigo{
                display: grid;
                width: 100%;
                grid-template-columns: repeat(2,1fr);
                justify-items: center;
                grid-gap: 5px;
                margin-bottom: 20px;
                .input-date{
                    display: grid;
                    text-align: start;
                    label{
                        padding: 0 15px;
                        font-size: 11px;
                        color:$color6;
                    }
                    input{
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 8px 10px;
                        border-radius: 16px;
                        @extend %glass1;
                        width: 95%;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    select{
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 8px 10px;
                        border-radius: 16px;
                        @extend %glass1;
                        width: 100%;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    option{
                        width: 100%;
                    }
                }
            }
        }
        .recaptcha-siigo{
            width: 100%;
            display: grid;
            grid-gap: 10px;
            position: relative;
            justify-content: center;
            text-align: center;
        }
    }
    .section-siigo-img {
        grid-area: section-siigo-img;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-height: 80vh;
        .img-siigo {
            width: 100%;
            height: 100%; 
            object-fit: cover;
            border-radius: 50px;
        }
    }
}

@media screen and (max-width: 850px){
    .section-contect-siigo{
        .section-siigo-contect-container{
            grid-template-columns: 1fr;
            grid-template-areas: "section-siigo-img"  "section-siigo-title";
        }
        .section-siigo-img.img-siigo{
            border-radius: 30px;
        }
    }
}


@media screen and (max-width: 550px){
    .section-contect-siigo .section-siigo-title .container-form-siigo .form-siigo {
        grid-template-columns: 1fr;
        .input-date input {
            font-family: Poppins;
            font-size: 11px;
            padding: 10px;
            border-radius: 12px;
            width: 95%;
            border: none;
        }
        .input-date select {
            font-family: Poppins;
            font-size: 11px;
            padding: 10px;
            border-radius: 12px;
            width: 100%;
            border: none;
        }
    }
    .section-contect-siigo .section-siigo-img .img-siigo {
        border-radius: 20px;
    }
    .section-contect-siigo .section-siigo-contect-container {
        margin-top: 100px;
        grid-gap: 2%;
    }
}


.input-message{
        display: grid;
        label{
            padding: 0 15px;
            font-size: 12px;
            color: $color8;
        }
        textarea{
            font-family: Poppins;
            font-size: 14px;
            padding: 10px 15px;
            border-radius: 16px;
            @extend %glass1;
            resize: vertical; 
            border: none; /* Agregado para eliminar el borde */
            &:focus, &:active {
                outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            }
        }
}
.recaptcha{
    display: grid;
    justify-content: center;
    margin: 10px;
    .g-recaptcha {
        transform:scale(0.7);
        transform-origin:0 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        border-radius: 20px;
    }
}

.exict-form-sigo{
    margin-top: 20px;
    border: $color6 2px solid;
    padding: 20px 40px;
    border-radius: 20px;
    display: grid;
    align-items: center;
    justify-items: center;
    align-content: center;
    text-align: center;
    svg{
        font-size: 45px;
    }
    .succeso{
        text-align: center;
    }
}
