@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


$fontcolor: #3f3f3f;
$color1:#1E67E2;
$color2:#4EA6D6;
$color3:#85D0D5;
$color4:#F4D037;
$color5:#964FE8;
$color6:#000;
$color7:#5c5c5c;
$color8:white;
$color9:#d1d1d1;
$color10:#ff4444;
$color11:#f7f7f7;
$color12:#ffa93a;
$color13:#6dcc35;


button{
    font-family: 'Poppins', sans-serif   !important;
    cursor: pointer;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.body-inte{
    min-height: 80vh;
}

.title{
    font-size: min(6vw, 50px);
    line-height: min(6vw, 55px) ;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
}

.title2{
    font-size: min(4vw, 35px);
    line-height: min(4vw, 35px) ;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
}

h4{
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
}


.subtitle1{
    font-size: min(5vw, 60px);
    line-height: min(5vw, 60px) ;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
}

.subtitle2{
    font-size: min(3vw, 22px);
    line-height: min(3.3vw, 25px);
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
    color: $fontcolor;
}

.subtitle3{
    font-size: min(4vw, 22px);
    line-height: min(4.3vw, 25px);
    font-weight: 800;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin: 0;
    padding: 0;
}

p{
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    padding: 0;
}

a{
    text-decoration: none !important;
    &:hover{
        text-decoration: none !important;
    }
}

@media screen and (max-width: 550px){
    p{
        font-size: 12px;
        line-height: 13px;
    }
}

.line{
    border-top: 1px solid $color9;
}

.edit{
    color: $color7;
}

.text-notification{
    padding: 5px;
    color: $color12;
}

.asigne{
    color: $color2;
}
.inactive{
    color: $color10;
}

.error-message{
    font-size: 10px;
    color: $color10;
}

$font: 'Poppins', sans-serif;
$fsize: 16px;

%glass{
    background: rgba(255, 255, 255, 0.822);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(14.2px);
    -webkit-backdrop-filter: blur(14.2px);
}

%glass1{
    background: rgba(255, 255, 255, 0.534);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(14.2px);
    -webkit-backdrop-filter: blur(14.2px);
}

%trans{
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: linear;
}

.container-alert{
    display: grid;
    width: 100vw;
    position: fixed;
    justify-items: center;
}

.alert-error{
    display: flex;
    bottom: 50px;
    grid-gap: 10px;
    position: fixed;
    font-size: 14px;
    min-width: 70%;
    text-align: center;
    padding: 15px 40px;
    background: $color10;
    border-radius: 16px;
    margin: 5px 40px;
    color: $color8;
    justify-content: center;
    align-items: center;
    svg{
        font-size: 25px;
    }
}

button{
    border: none;
    background: none;
}

.button-nav{
    color: $color11;
    background: $color3;
    border: $color3 solid 2px;
    padding: 10px 30px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 20px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    &:hover{
        color: $color8;
        background: none;
        border: $color8 solid 2px;
        @extend %trans;
        svg{
            font-size: 24px;
        }
    }
}


.button-nav2{
    color: $color11;
    background: $color2;
    border: $color2 solid 2px;
    padding: 10px 30px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 20px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    &:hover{
        color: $color2;
        background: none;
        border: $color2 solid 2px;
        @extend %trans;
        svg{
            font-size: 24px;
        }
    }
}


.button-new{
    color: $color11;
    background: #1E67E2;
    border: #1E67E2 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    img{
        height: 85px;
        width: auto;
        margin: -30px;
        @extend %trans;
    }
    &:hover{
        color: #1E67E2;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 95px;
            width: auto;
            margin: -35px;
            @extend %trans;
        }
    }
}

.button-whtaspp{
    color: $color11;
    background: $color13;
    border: $color13 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    img{
        height: 85px;
        width: auto;
        margin: -30px;
        @extend %trans;
    }
    &:hover{
        color: $color13;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 95px;
            width: auto;
            margin: -35px;
            @extend %trans;
        }
    }
}

.button-new3{
    color: $color11;
    background: #1E67E2;
    border: $color1 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    img{
        height: 85px;
        width: auto;
        margin: -30px;
        @extend %trans;
    }
    &:hover{
        color: $color11;
        border: $color11 solid 2px;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 95px;
            width: auto;
            margin: -35px;
            @extend %trans;
        }
    }
}


@media screen and (max-width: 850px){
    .button-new{
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 15px;
        img{
            height: 70px;
            width: auto;
            margin: -20px;
            @extend %trans;
        }
        svg{
            font-size: 22px;
        }
        &:hover{
            svg{
                font-size: 22px;
            }
            img{
                height: 75px;
                margin: -25px;
            }
        }
    }
    .button-whtaspp{
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 15px;
        img{
            height: 70px;
            width: auto;
            margin: -20px;
            @extend %trans;
        }
        svg{
            font-size: 22px;
        }
        &:hover{
            svg{
                font-size: 22px;
            }
            img{
                height: 75px;
                margin: -25px;
            }
        }
    }
}


.button-asing{
    color: $color11;
    background: $color12;
    border: $color12 solid 2px;
    padding: 10px 30px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 50px;
    font-size: 20px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    img{
        height: 120px;
        width: auto;
        margin: -50px;
        @extend %trans;
    }
    &:hover{
        color: $color12;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 140px;
            width: auto;
            margin: -60px;
            @extend %trans;
        }
    }
}


@media screen and (max-width: 850px){
    .button-asing{
        padding: 10px 16px;
        border-radius: 16px;
        grid-gap: 30px;
        font-size: 13px;
        img{
            height: 70px;
            width: auto;
            margin: -45px;
            @extend %trans;
        }
        &:hover{
            padding: 10px 20px;
            color: $color12;
            background: none;
            @extend %trans;
            svg{
                font-size: 24px;
            }
            img{
                height: 75px;
                margin: -40px;
            }
        }
    }
}

.button-password{
    color: $color11;
    background: #85D0D5;
    border: #85D0D5 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    svg{
        font-size: 22px;
    }
    img{
        height: 85px;
        width: auto;
        margin: -30px;
        @extend %trans;
    }
    &:hover{
        color: #85D0D5;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 95px;
            width: auto;
            margin: -35px;
            @extend %trans;
        }
    }
}

@media screen and (max-width: 850px){
    .button-password{
        font-size: 11px;
        padding: 8px;
        border-radius: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
        img{
            height: 70px;
            width: auto;
            margin: -20px;
            @extend %trans;
        }
        svg{
            font-size: 22px;
        }
        &:hover{
            svg{
                font-size: 22px;
            }
            img{
                height: 75px;
                margin: -25px;
            }
        }
    }
}

.button-question{
    color: $color11;
    background: #4EA6D6;
    border: #4EA6D6 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    svg{
        font-size: 22px;
    }
    &:hover{
        color: #4EA6D6;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 850px){
    .button-question{
        font-size: 11px;
        padding: 8px;
        border-radius: 15px;
        margin-top: 0px;
        margin-bottom: 0px;
        svg{
            font-size: 22px;
        }
        &:hover{
            svg{
                font-size: 22px;
            }
        }
    }
}



.button-new2{
    color: $color11;
    background: $color2;
    border: $color2 solid 2px;
    padding: 10px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    img{
        height: 85px;
        width: auto;
        margin: -30px;
        @extend %trans;
    }
    &:hover{
        color: $color2;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
        img{
            height: 95px;
            width: auto;
            margin: -35px;
            @extend %trans;
        }
    }
}

.button-delete{
    color: $color11;
    background: $color10;
    border: $color10 solid 2px;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    &:hover{
        color: $color10;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 850px){
    .button-delete{
        font-size: 11px;
        padding: 5px 10px;
        border-radius: 15px;
        &:hover{
            svg{
                font-size: 23px;
            }
        }
    }
}

.button-x{
    position: relative;
    z-index: 999;
    display: flex;
    border: none;
    background: none;
    top: 20px;
    left: 20px;
    cursor: pointer;
    @extend %trans;
    margin-bottom: -50px;
    svg{
        background: $color2;
        border: 2px solid $color2;
        color: $color8;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color2;
            @extend %trans;
            background: none;
        }
    }
}

.button-x-starco{
    position:fixed;
    z-index: 999;
    display: flex;
    border: none;
    background: none;
    top: 20px;
    right: 20px;
    cursor: pointer;
    @extend %trans;
    margin-bottom: -50px;
    svg{
        background: $color2;
        border: 2px solid $color2;
        color: $color8;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color2;
            @extend %trans;
            background: none;
        }
    }
}

.button-delete-x{
    position: fixed;
    z-index: 999;
    display: flex;
    border: none;
    background: none;
    top: 20px;
    left: 20px;
    cursor: pointer;
    @extend %trans;
    svg{
        background: $color2;
        border: 2px solid $color2;
        color: $color8;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color2;
            @extend %trans;
            background: none;
        }
    }
}

.button-save{
    color: $color11;
    background: $color13;
    border: $color13 solid 2px;
    padding: 10px 15px;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 14px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    svg{
        font-size: 22px;
    }
    &:hover{
        color: $color13;
        background: none;
        @extend %trans;
        svg{
            font-size: 24px;
        }
    }
}

.loading-container {
    position: absolute;
    display: grid;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #ffffff;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 9999;
}

.loading-container-Nc {
    position: absolute;
    display: grid;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right top, #091216, #0b1519, #0c171d, #0e1a20, #0e1c24, #112733, #153242, #183d52, #1e5574, #246f97, #2a89bc, #31a4e3);
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 9999;
}

.loading-section {
    position: absolute;
    display: grid;
    width: 100%;
    height: 100%;
    background: #ffffff;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 9999;
}

.info-loading-container {
    position: absolute;
    display: grid;
    min-height: 83vh;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 40px;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 9999;
}

.loading {
    position: fixed;
    display: grid;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #ffffff;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    z-index: 9999;
}

.section-container{
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    padding: 0 0 10px 0;
    background: #ffffff;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.div-container{
    display: grid;
    width: 100%;
    height: 200px;
    padding: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    color: $fontcolor;
    grid-gap: 15px;
}

.loading-circle {
    border: 8px solid #f3f3f3;
    border-top: 8px solid $color1;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.loading-circleNC {
    border: 8px solid #f3f3f388;
    border-top: 8px solid $color3;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
    border: none !important;
}

.leaflet-touch .leaflet-bar a:first-child {
    border: none !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    border-radius: 50% !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.leaflet-touch .leaflet-bar a:last-child {
    border: none !important;
    border-top-left-radius: 2px !important;
    border-top-right-radius: 2px !important;
    border-radius: 50% !important;
}

.shepherd-text{
    padding-top: 100px !important;
    font-size: 13px !important;
    line-height: 13px !important;
}
//_____________________________________________________________________________________________________
//_____________________________________________________________________________________________________
// Visita guiada
//_____________________________________________________________________________________________________
.shepherd-content {
    border-radius: 25px !important;
}

.shepherd-content {
    border-radius: 25px !important;

}

.shepherd-element{
    border-radius: 25px !important;
}

.shepherd-header {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
}

.shepherd-button{
    background: #1E67E2;
    border-radius: 16px !important;
}

.shepherd-has-title .shepherd-content .shepherd-header{
    max-width: 400px;
}


.input-date-filter{
    display: grid;
    width: 90%;
    padding: 0 5% 0 0;
    label{
        padding: 0 15px;
        font-size: 11px;
        color:$color7;
    }
    input{
        width: 95%;
        font-family: Poppins;
        font-size: 14px;
        padding: 8px 10px;
        border-radius: 16px;
        @extend %glass1;
        border: none; /* Agregado para eliminar el borde */
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
        }
    }
    select{
        font-family: Poppins;
        font-size: 14px;
        padding: 8px 10px;
        border-radius: 16px;
        border: none;
        @extend %glass1;
        border: none; /* Agregado para eliminar el borde */
        &:focus, &:active {
            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            border: none;
        }
    }
}


// Nuevas Variables fijas
.title-page{
    font-size: 50px;
    line-height: 65px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color: $fontcolor;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .title-page{
        font-size: 36px;
        line-height: 49px;
    }
}

.title-page-1{
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color: $fontcolor;
    span{
        font-weight: 600;
        color: $color1;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .title-page-1{
        font-size: 24px;
        line-height: 38px;
    }
}

.title-page-2{
    font-size: 30px;
    line-height: 48px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color:$fontcolor;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

.title-page-3{
    font-size: 36px;
    line-height: 50px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color: $color11;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .title-page-3{
        font-size: 24px;
        line-height: 35px;
    }
}


.title-page-4{
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color: $fontcolor;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .title-page-4{
        font-size: 16px;
        line-height: 25px;
    }
}


.title-page-5{
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color: $color1;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

.title-page-6{
    font-size: 18px;
    line-height: 19px;
    font-weight: 600;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 10px 0;
    color: $color1;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}


.subtitle-page{
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    color: $fontcolor;
    span{
        color: $color2;
        font-weight: 600;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .subtitle-page{
        font-size: 16px;
        line-height: 22px;
    }
}

.subtitle-page-1{
    font-size: 20px;
    line-height: 35px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    max-width: 600px;
    color: $fontcolor;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .subtitle-page-1{
        font-size: 16px;
        line-height: 18px;
    }
}

.subtitle-page-2{
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    max-width: 600px;
    color: $fontcolor;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}


.subtitle-page-3{
    font-size: 14px;
    text-align: start;
    line-height: 20px;
    font-weight: 400;
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding: 0;
    max-width: 600px;
    color: $fontcolor;
    span{
        font-weight: 600;
        font-style: oblique;
    }
}

@media screen and (max-width:600px){
    .subtitle-page-3{
        font-size: 11px;
        line-height: 16px;
    }
}



.button-tipe1{
    color: $color11;
    background: $color2;
    border: $color2 solid 2px;
    padding: 5px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 16px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 16px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    z-index: 99;
    svg{
        font-size: 18px;
    }
    &:hover{
        color: $color2;
        background: none;
        border: $color2 solid 2px;
        @extend %trans;
        svg{
            font-size: 19px;
        }
    }
}

@media screen and (max-width:600px){
    .button-tipe1{
        font-size: 12px;
        padding: 8px 15px;
    }
}

.button-tipe2{
    color: $color11;
    background: $color1;
    border: $color1 solid 2px;
    padding: 5px 15px;
    font-weight: 500;
    text-decoration: none;
    border-radius: 18px;
    display: flex;
    align-content: center;
    align-items: center;
    grid-gap: 10px;
    font-size: 16px;
    @extend %trans;
    justify-content: center;
    font-family: $font;
    cursor: pointer;
    z-index: 5;
    svg{
        font-size: 18px;
    }
    &:hover{
        color: $color1;
        background: none;
        border: $color1 solid 2px;
        @extend %trans;
        svg{
            font-size: 19px;
        }
    }
}

@media screen and (max-width:600px){
    .button-tipe2{
        font-size: 12px;
        padding: 8px 15px;
    }
}

.button-3-card{
    text-align: left;
    color: $color1;
    font-size: 14px;
    font-weight: 500;
    @extend %trans;
    &:hover{
        font-size: 15px;
        color: $color3;
        @extend %trans;
        margin: -2px;
    }
}

@media screen and (max-width:600px){
    .button-3-card{
        font-size: 12px;
    }
}

@media (max-width: 750px) {
    .section-form-starco .container-form-starco .title-form-startco {
        font-size: 25px;
        line-height: 25px;
    }
}


.button-x-close{
    position: fixed;
    z-index: 999;
    display: flex;
    border: none;
    background: none;
    top: 20px;
    right: 20px;
    cursor: pointer;
    @extend %trans;
    margin-bottom: -50px;
    svg{
        background: $color2;
        border: 2px solid $color2;
        color: $color8;
        font-size: 25px;
        @extend %trans;
        padding: 10px;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color2;
            @extend %trans;
            background: none;
        }
    }
}


// Formato de 
.option-button{
    font-size: 12px;
    padding: 10px;
    width: 100%;
    border-radius: 14px;
}

.selected-module{
    border: 2px solid $color7;
    color: $color7;
}

.of-selected-module{
    border: 2px solid $color2;
    color: $color2;
}