@import "../../../../Constant.scss";

.section-location{
    display: grid;
    justify-items: center;
    z-index: 9999;
    .container-location-users{
        display: grid;
        width: 100%;
        color:$color8;
        text-align: center;
        grid-gap: 10px;
        margin: 60px 0;
        @extend %glass;
        background: #fffffff3;
        padding: 20px;
        border-radius: 50px;
        .brand{
            display: grid;
            justify-items: center;
            img{
                margin-top: 30px;
                width: 50%;
                max-width: 120px;
                height: auto;
            }
        }
        .subtitle1{
            color: $color6;
            span{
                color: $color2;
            }
        }
        .subtitle2{
            color: $color7;
            margin-bottom: 20px;
        }
        .form-contact{
            text-align: left;
            display: grid;
            grid-gap: 5px;
            padding: 0 5% 10px 5%;
            .form-contact-dates{
                grid-gap: 10px;
                display: grid;
                grid-template-columns: repeat(2,1fr);
            }
            input{
                background-color: white !important; /* Establece el fondo en blanco */
            }
            .input-date{
                display: grid;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 11px;
                    color:$color7;
                }
                input{
                    // width: 95%;
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 10px;
                    border-radius: 16px;
                    color: $color7;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 8px 10px;
                    border-radius: 16px;
                    @extend %glass1;
                    color: $color7;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                    overflow-y: auto;
                    max-height: 100px;
                    min-height: 20px;
                    /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba(169, 169, 169, 0.562);
                        border-radius: 5px;
                    }
                    /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
                    &::-webkit-scrollbar-track {
                        @extend %glass;
                        border-radius: 5px;
                    }
                    /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
                    &::-webkit-scrollbar {
                        width: 8px;
                    }
                    .display-button{
                        display: flex;
                        flex-wrap: wrap;
                        gap: 2px; 
                    }
                }
            }
            .input-message{
                display: grid;
                label{
                    padding: 0 15px;
                    font-size: 12px;

                }
                textarea{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    resize: vertical; 
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .button-form{
                font-family: Poppins;
                font-size: 16px;
                padding: 8px;
                font-weight: 700;
                color: $color8;
                border: 2px solid $color2;
                border-radius: 16px;
                background: $color2;
                margin-top: 20px;
                @extend %trans;
                cursor: pointer;
                &:hover{
                    font-weight: 700;
                    color: $color2;
                    border: 2px solid $color2;
                    border-radius: 16px;
                    background: none;
                    @extend %trans;
                }
            }
        }
        .password-reset{
            margin-bottom: 20px;
            color: $color7;
            @extend %trans;
            &:hover{
                color: $color2;
                @extend %trans;
            }
        }
    }
}

.button-x-close{
    position:fixed;
    z-index: 999;
    display: flex;
    border: none;
    background: none;
    top: 20px;
    right: 20px;
    cursor: pointer;
    @extend %trans;
    margin-bottom: -50px;
    svg{
        background: $color2;
        border: 2px solid $color2;
        color: $color8;
        font-size: 35px;
        @extend %trans;
        padding: 10px;
        border-radius: 50%;
    }
    &:hover{
        @extend %trans;
        svg{
            color: $color2;
            @extend %trans;
            background: none;
        }
    }
}

.css-ol7wxs-MuiTypography-root{
    font-family: 'Poppins', sans-serif   !important;
    font-size: 30px !important;
    font-weight: 700  !important;
}

.container-progress{
    font-family: 'Poppins', sans-serif   !important;
    display: grid;
    justify-content: center;
    justify-items: center;
    text-align: center;
    .title-progress{
        font-size: 20px;
        line-height: 20px;
        color:rgb(56, 56, 56);
    }
}

.container-bulkupload{
    display: grid;
    justify-content: center;
    grid-gap: 20px;
    padding: 20px 40px;
    justify-items: center;
    .errortext{
        padding: 20px;
        overflow-y: auto;
        max-height: 15vh;
        min-height: 15vh;
        background: #eeeeeec9;
        border-radius: 16px;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 5px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 5px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        p{
            line-height: 18px;
            color: $color10;
            font-size: 14px;
            text-align: start;
        }
    }
    .aprovedtext{
        width: 90%;
        padding: 20px;
        overflow-y: auto;
        max-height: 15vh;
        min-height: 15vh;
        background: #eeeeeec9;
        border-radius: 16px;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 5px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 5px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
        p{
            line-height: 18px;
            color:$color7;
            font-size: 14px;
            text-align: start;
        }
    }
    .container-button-copy{
        margin-top: -40px;
        display: flex;
        width: 100%;
        justify-content: end;
    }
}