@import "../../../../Constant.scss";

.alert-close{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    grid-gap: 10px;
    border: 2px solid #ff4444;
    color: #ff4444;
    border-radius: 12px;
    padding: 5px;
    background: #ff444418;
    a{
        color: #ff4444;
    }
}

.equipo-admin{
    padding: 2vh 2vh 0% 2vh;
    display: flex;
    flex-direction: column;
    grid-gap: 2vh;
    height: 80vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    .encabezado-users-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .encabezado-users-button{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            align-items: center;
        }
    }
    .form-contact-e{
        text-align: left;
        display: grid;
        width: 100%;
        grid-template-columns: 16vw auto;
        grid-gap: 5%;
        .column-img{
            display: grid;
            grid-gap: 10px;
            .custom-file-upload{
                color: $color11;
                background: #1E67E2;
                border: #1E67E2 solid 2px;
                padding: 10px 15px;
                text-decoration: none;
                border-radius: 16px;
                display: flex;
                align-content: center;
                align-items: center;
                grid-gap: 10px;
                font-size: 14px;
                @extend %trans;
                justify-content: center;
                font-family: $font;
                margin-bottom: 30px;
                cursor: pointer;
                svg{
                    font-size: 22px;
                }
                &:hover{
                    color: #1E67E2;
                    background: none;
                    @extend %trans;
                    svg{
                        font-size: 24px;
                        color: #1E67E2;
                    }
                }
            }
        }
        .column-form{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            padding: 30px 20px;
            border-radius: 30px;
            @extend %glass;
            align-items: center;
            .input-date{
                display: grid;
                width: 100%;
                label{
                    padding: 0 15px;
                    font-size: 12px;
                }
                input{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                select{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
                option{
                    font-family: Poppins;
                    font-size: 14px;
                    padding: 10px 15px;
                    border-radius: 16px;
                    @extend %glass1;
                    border: none; /* Agregado para eliminar el borde */
                    &:focus, &:active {
                        outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                    }
                }
            }
            .input-date:nth-child(1) {
                grid-column: span 2;
            }
            .input-date:nth-child(2) {
                grid-column: span 2;
            }
            .input-date:nth-child(3) {
                grid-column: span 2;
            }
            .input-date:nth-child(4) {
                grid-column: span 1;
            }
            .input-date:nth-child(5) {
                grid-column: span 1;
            }
            .input-date:nth-child(6) {
                grid-column: span 1;
            }
            .input-date:nth-child(7) {
                grid-column: span 1;
            }
            .input-date:nth-child(8) {
                grid-column: span 1;
            }
            .input-date:nth-child(9) {
                grid-column: span 1;
            }
            .input-date:nth-child(10) {
                grid-column: span 1;
            }
            .input-date:nth-child(11) {
                grid-column: span 1;
            }
            .input-date:nth-child(12) {
                grid-column: span 2;
            }
            .input-date:nth-child(13) {
                grid-column: span 2;
            }
            .input-date:nth-child(14) {
                grid-column: span 2;
            }
            .input-date:nth-child(15) {
                grid-column: span 4;
            }
            .input-date:nth-child(16) {
                grid-column: span 4;
            }
        }
        .section-img{
            display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            align-content: center;
            grid-gap: 30px;
            margin-bottom: 60px;
            .input-img{
                width:  max(15vw, 200px);
                height: max(15vw, 200px);
                border-radius: 50%; /* Esto crea un círculo */
                overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                position: relative; /* Permite centrar vertical y horizontalmente */
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                    object-position: center center; /* Centra la imagen */
                }
            }
        }
        .input-message{
            display: grid;
            label{
                padding: 0 15px;
                font-size: 12px;
                color: $color8;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                @extend %glass1;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-button{
            display: flex;
            margin-top: 15px;
        }
    }
    .scroll-equip-admin{
        max-height: 64vh;
        padding: 20px 30px 10px 30px;
        align-items: center;
        justify-items: center;
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
}

.windons-user{
    padding: 2vh 2vh 0% 2vh;
    display: flex;
    flex-direction: column;
    grid-gap: 2vh;
    height: 80vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    
}

.windons-quiz-init{
    height: 100%;
    display: grid;
    position: relative;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    .button-load-cvs{
        color: $color11;
        background: $color12;
        border: $color12 solid 2px;
        padding: 10px 30px;
        text-decoration: none;
        border-radius: 16px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 50px;
        font-size: 20px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        cursor: pointer;
        svg{
            font-size: 22px;
        }
        img{
            height: 120px;
            width: auto;
            margin: -50px;
            @extend %trans;
        }
        &:hover{
            color: $color12;
            background: none;
            @extend %trans;
            svg{
                font-size: 24px;
            }
            img{
                height: 140px;
                width: auto;
                margin: -60px;
                @extend %trans;
            }
        }
    }
    .custom-file-upload{
        color: $color11;
        background: #1E67E2;
        border: #1E67E2 solid 2px;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 16px;
        display: flex;
        align-content: center;
        align-items: center;
        grid-gap: 10px;
        font-size: 14px;
        @extend %trans;
        justify-content: center;
        font-family: $font;
        margin-bottom: 30px;
        cursor: pointer;
        svg{
            font-size: 22px;
            color: $color11;
        }
        &:hover{
            color: #1E67E2;
            background: none;
            @extend %trans;
            svg{
                font-size: 24px;
                color: #1E67E2;
            }
        }
    }
    .section-quiz-init{
        display: grid;
        grid-gap: 50px;
        grid-template-columns: repeat(2,1fr);
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        margin-top: -10vh;
        .title-shofi-loanding{
            font-size: 2.2vw;
            line-height: 2.2vw;
            text-align: center;
            color: $color7;
        }
        .quiz-init-img{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            align-items: center;
            img{
                height: 80%;
                width: auto;
                max-width: 400px;
                align-items: center;
            }
        }
        .quiz-init-contect{
            text-align: center;
            align-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
            grid-gap: 50px;

            .button-download{
                border: none;
                background: none;
                font-size: 20px;
                line-height: 0dvh;
                font-family: $font;
                color: $color1;
                cursor: pointer;
                font-weight: 500;
                &:hover{
                    color: $color7;
                }
            }
        }
    }
    .section-quiz-init2{
        display: grid;
        grid-gap: 50px;
        grid-template-columns: 1fr;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        .title-shofi-loanding{
            font-size: 2.2vw;
            line-height: 2.2vw;
            text-align: center;
            color: $color7;
        }
        .quiz-init-img{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            align-items: center;
            img{
                height: 80%;
                width: auto;
                max-width: 400px;
                align-items: center;
            }
        }
        .quiz-init-contect{
            align-items: center;
            display: flex;
            flex-direction: column;
            grid-gap: 50px;
            .button-download{
                border: none;
                background: none;
                font-size: 20px;
                line-height: 0dvh;
                font-family: $font;
                color: $color1;
                cursor: pointer;
                font-weight: 500;
                &:hover{
                    color: $color7;
                }
            }
        }
    }
}

@media screen and (max-width: 1600px){
    .equipo-admin{
        .form-contact-e{
            grid-template-columns: 200px auto;
            grid-gap: 30px;
            .column-form{
                grid-template-columns: repeat(2, 1fr);
            }
            .column-img {
                align-items: start;
                .custom-file-upload{
                    margin-bottom: 0px;
                    font-size: 11px;
                    padding: 8px 12px;
                    border-radius: 15px;
                }
            }
        }
        .default{
            display: none;
        }
        .scroll-equip-admin{
            max-height: 60vh;
            padding: 20px 20px 10px 20px;
        }
    }
}

@media screen and (max-width: 1200px){
    .equipo-admin{
        .form-contact-e{
            .column-form{
                grid-template-columns:1fr;
                .input-date:nth-child(1) {
                    grid-column: span 1;
                }
                .input-date:nth-child(2) {
                    grid-column: span 1;
                }
                .input-date:nth-child(3) {
                    grid-column: span 1;
                }
                .input-date:nth-child(4) {
                    grid-column: span 1;
                }
                .input-date:nth-child(5) {
                    grid-column: span 1;
                }
                .input-date:nth-child(6) {
                    grid-column: span 1;
                }
                .input-date:nth-child(7) {
                    grid-column: span 1;
                }
                .input-date:nth-child(8) {
                    grid-column: span 1;
                }
                .input-date:nth-child(9) {
                    grid-column: span 1;
                }
                .input-date:nth-child(10) {
                    grid-column: span 1;
                }
                .input-date:nth-child(11) {
                    grid-column: span 1;
                }
                .input-date:nth-child(12) {
                    grid-column: span 1;
                }
                .input-date:nth-child(13) {
                    grid-column: span 1;
                }
                .input-date:nth-child(14) {
                    grid-column: span 1;
                }
                .input-date:nth-child(15) {
                    grid-column: span 1;
                }
                .input-date:nth-child(16) {
                    grid-column: span 1;
                }
                .input-date:nth-child(17) {
                    grid-column: span 1;
                }
                .input-date:nth-child(18) {
                    grid-column: span 1;
                }
                .input-date:nth-child(19) {
                    grid-column: span 1;
                }
                .input-date:nth-child(20) {
                    grid-column: span 1;
                }
            }
        }
    }
}

@media screen and (max-width: 800px){
    .equipo-admin{
        .encabezado-list{
            .title{
                display: none;
            }
        }
        .form-contact-e{
            grid-template-columns: 1fr;
            .section-img{
                grid-gap: 10px;
                margin-bottom: 5px;
            }
            .column-form{
                padding: 20px 20px;
                .input-date input{
                    font-size: 12px;
                    padding: 8px 12px;
                    border-radius: 14px;
                }
                .input-date label {
                    padding: 0 12px;
                    font-size: 10px;
                }
                .input-date select {
                    font-size: 12px;
                    padding: 8px 12px;
                    border-radius: 14px;
                }
                .input-date option{
                    font-size: 12px;
                    padding: 8px 12px;
                    border-radius: 14px;
                    border: none;
                }
            }
        }
        .scroll-equip-admin{
            padding: 20px 15px 10px 15px;
        }
    }
}


.windons-equipo-admin{
    padding: 2vh 2vh 0% 2vh;
    display: flex;
    flex-direction: column;
    grid-gap: 2vh;
    height: 85vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
    }
    .encabezado-users-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .encabezado-users-button{
            display: flex;
            grid-gap: 20px;
            justify-content: space-between;
            align-items: center;
        }
    }
    .form-contact-e{
        text-align: left;
        display: grid;
        width: 100%;
        grid-template-columns: 16vw auto;
        grid-gap: 5%;
        .column-img{
            display: grid;
            grid-gap: 10px;
            .custom-file-upload{
                color: $color11;
                background: #1E67E2;
                border: #1E67E2 solid 2px;
                padding: 10px 15px;
                text-decoration: none;
                border-radius: 16px;
                display: flex;
                align-content: center;
                align-items: center;
                grid-gap: 10px;
                font-size: 14px;
                @extend %trans;
                justify-content: center;
                font-family: $font;
                margin-bottom: 30px;
                cursor: pointer;
                svg{
                    font-size: 22px;
                }
                &:hover{
                    color: #1E67E2;
                    background: none;
                    @extend %trans;
                    svg{
                        font-size: 24px;
                        color: #1E67E2;
                    }
                }
            }
        }
        .section-users{
            display: flex;
            flex-direction: column;
            grid-gap: 10px;
            padding: 30px 20px;
            border-radius: 30px;
            @extend %glass;
            .column-form2{
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 10px;
                align-items: center;
                .input-date{
                    display: grid;
                    width: 100%;
                    label{
                        padding: 0 15px;
                        font-size: 12px;
                    }
                    .error-input-label{
                        padding: 0 15px;
                        font-size: 12px;
                        color:$color10;
                    }
                    input{
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 10px 15px;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                    select{
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 10px 15px;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                        .error-input{
                            color:$color10 !important;
                        }
                    }
                    option{
                        font-family: Poppins;
                        font-size: 14px;
                        padding: 10px 15px;
                        border-radius: 16px;
                        @extend %glass1;
                        border: none; /* Agregado para eliminar el borde */
                        &:focus, &:active {
                            outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                        }
                    }
                }
                .input-date:nth-child(1) {
                    grid-column: span 2;
                }
                .input-date:nth-child(2) {
                    grid-column: span 2;
                }
                .input-date:nth-child(3) {
                    grid-column: span 2;
                }
                .input-date:nth-child(4) {
                    grid-column: span 1;
                }
                .input-date:nth-child(5) {
                    grid-column: span 1;
                }
                .input-date:nth-child(6) {
                    grid-column: span 1;
                }
                .input-date:nth-child(7) {
                    grid-column: span 1;
                }
                .input-date:nth-child(8) {
                    grid-column: span 1;
                }
                .input-date:nth-child(9) {
                    grid-column: span 1;
                }
                .input-date:nth-child(10) {
                    grid-column: span 1;
                }
                .input-date:nth-child(11) {
                    grid-column: span 1;
                }
                .input-date:nth-child(12) {
                    grid-column: span 2;
                }
                .input-date:nth-child(13) {
                    grid-column: span 2;
                }
                .input-date:nth-child(14) {
                    grid-column: span 2;
                }
                .input-date:nth-child(15) {
                    grid-column: span 4;
                }
                .input-date:nth-child(16) {
                    grid-column: span 4;
                }
            }
        }
        .section-img{
            display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            align-content: center;
            grid-gap: 30px;
            margin-bottom: 60px;
            .input-img{
                width:  max(15vw, 200px);
                height: max(15vw, 200px);
                border-radius: 50%; /* Esto crea un círculo */
                overflow: hidden; /* Corta cualquier contenido fuera del círculo */
                position: relative; /* Permite centrar vertical y horizontalmente */
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Corta la imagen para que se ajuste al círculo */
                    object-position: center center; /* Centra la imagen */
                }
            }
        }
        .input-message{
            display: grid;
            label{
                padding: 0 15px;
                font-size: 12px;
                color: $color8;
            }
            textarea{
                font-family: Poppins;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 16px;
                @extend %glass1;
                resize: vertical; 
                border: none; /* Agregado para eliminar el borde */
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
        }
        .input-button{
            display: flex;
            margin-top: 15px;
        }
    }
    .scroll-equip-admin{
        max-height: 80vh;
        padding:2vh;
        align-items: center;
        // justify-items: center;
        overflow-y: auto; 
        overflow-x: hidden;
        /* Estilo para el thumb del scrollbar (la barra que se arrastra) */
        &::-webkit-scrollbar-thumb {
            background-color: rgba(169, 169, 169, 0.562);
            border-radius: 10px;
        }
        /* Estilo para la pista del scrollbar (donde se desliza el thumb) */
        &::-webkit-scrollbar-track {
            @extend %glass;
            border-radius: 10px;
        }
        /* Estilo para el scrollbar completo (puede usarse para ajustar el ancho, por ejemplo) */
        &::-webkit-scrollbar {
            width: 8px;
        }
    }
}


@media screen and (max-width: 1600px){
    .windons-equipo-admin{
        .form-contact-e{
            grid-template-columns: 200px auto;
            grid-gap: 30px;
            .section-users{
                .column-form2{
                    grid-template-columns: repeat(2, 1fr);
                }
            }
            .column-img {
                align-items: start;
                .custom-file-upload{
                    margin-bottom: 0px;
                    font-size: 11px;
                    padding: 8px 12px;
                    border-radius: 15px;
                }
            }
        }
        .default{
            display: none;
        }
        .scroll-equip-admin{
            max-height: 85vh;
        }
    }
}

@media screen and (max-width: 1200px){
    .windons-equipo-admin{
        .form-contact-e{
            .section-users{
                .column-form2{
                    grid-template-columns:1fr;
                    .input-date:nth-child(1) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(2) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(3) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(4) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(5) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(6) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(7) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(8) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(9) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(10) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(11) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(12) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(13) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(14) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(15) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(16) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(17) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(18) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(19) {
                        grid-column: span 1;
                    }
                    .input-date:nth-child(20) {
                        grid-column: span 1;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px){
    .windons-equipo-admin{
        .encabezado-list{
            .title{
                display: none;
            }
        }
        .form-contact-e{
            grid-template-columns: 1fr;
            .section-img{
                grid-gap: 10px;
                margin-bottom: 5px;
            }
            .section-users{
                padding: 20px 20px;
                .column-form2{
                    
                    .input-date input{
                        font-size: 12px;
                        padding: 8px 12px;
                        border-radius: 14px;
                    }
                    .input-date label {
                        padding: 0 12px;
                        font-size: 10px;
                    }
                    .input-date select {
                        font-size: 12px;
                        padding: 8px 12px;
                        border-radius: 14px;
                    }
                    .input-date option{
                        font-size: 12px;
                        padding: 8px 12px;
                        border-radius: 14px;
                        border: none;
                    }
                }
            }
        }
        .scroll-equip-admin{
            padding: 20px 15px 10px 15px;
        }
    }
}

.windons-user-cvs{
    display: grid;
    padding: 2vh 2vh 0% 2vh;
    grid-template-rows: 7vh 78vh;
    .encabezado-list{
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        justify-content: space-between;
        .title-equip{
            display: flex;
            font-size: min(6vw, 50px);
            line-height: min(6vw, 55px) ;
            margin-block-start: 0em;
            margin-block-end: 0em;
            margin: 10px;
            padding: 0;
        }
    }
    .windons-quiz-init{
        height: 100%;
        display: grid;
        position: relative;
        align-content: center;
        justify-content: center;
        align-items: center;
        justify-items: center;
        .button-load-cvs{
            color: $color11;
            background: $color12;
            border: $color12 solid 2px;
            padding: 10px 30px;
            text-decoration: none;
            border-radius: 16px;
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 50px;
            font-size: 20px;
            @extend %trans;
            justify-content: center;
            font-family: $font;
            cursor: pointer;
            svg{
                font-size: 22px;
            }
            img{
                height: 120px;
                width: auto;
                margin: -50px;
                @extend %trans;
            }
            &:hover{
                color: $color12;
                background: none;
                @extend %trans;
                svg{
                    font-size: 24px;
                }
                img{
                    height: 140px;
                    width: auto;
                    margin: -60px;
                    @extend %trans;
                }
            }
        }
        .custom-file-upload{
            color: $color11;
            background: #1E67E2;
            border: #1E67E2 solid 2px;
            padding: 10px 15px;
            text-decoration: none;
            border-radius: 16px;
            display: flex;
            align-content: center;
            align-items: center;
            grid-gap: 10px;
            font-size: 14px;
            @extend %trans;
            justify-content: center;
            font-family: $font;
            margin-bottom: 30px;
            cursor: pointer;
            svg{
                font-size: 22px;
                color: $color11;
            }
            &:hover{
                color: #1E67E2;
                background: none;
                @extend %trans;
                svg{
                    font-size: 24px;
                    color: #1E67E2;
                }
            }
        }
        .section-quiz-init{
            display: grid;
            grid-gap: 5vh;
            grid-template-columns: repeat(2,1fr);
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            .title-shofi-loanding{
                font-size: 2.2vw;
                line-height: 2.2vw;
                margin-block-start: 0px;
                margin-block-end: 0px;
                margin-inline-start: 0px;
                margin-inline-end: 0px;
                text-align: center;
                color: $color7;
            }
            .quiz-init-img{
                display: flex;
                flex-direction: column;
                height: 100%;
                width: 100%;
                align-items: center;
                img{
                    height: auto;
                    width: 80%;
                    max-width: 400px;
                    align-items: center;
                }
            }
            .quiz-init-contect{
                text-align: center;
                align-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                grid-gap: 2vh;
                .button-download{
                    border: none;
                    background: none;
                    font-size: 20px;
                    line-height: 0vh;
                    font-family: $font;
                    color: $color1;
                    cursor: pointer;
                    font-weight: 500;
                    &:hover{
                        color: $color7;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 850px){
    .windons-user-cvs{
        .encabezado-list{
            .title-equip{
                display: none;
            }
        }
        .windons-quiz-init{
            .section-quiz-init{
                grid-template-columns: 90%;
                grid-gap: 0px;
                .title-shofi-loanding{
                    font-size: 4vw;
                    line-height: 4vw;
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                }
                .quiz-init-contect{
                    .button-download{
                        font-size: 14px;
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}